<template>
    <div class="container">
        <h1>Testes:</h1>
        <div class="row mb-2">
            <strong>Curso selecionado: {{curso_selecionado}}</strong><br>
        </div>
        <div class="row">
            <div class="col-6 mt-1 mb-1">
                <b-form-select placeholder="Escolha um curso" v-model="curso_selecionado" :options="cursos_disponiveis"></b-form-select>
            </div>
            <div class="col-6 mt-1 mb-1">
                <b-form-select placeholder="Escolha um Item" v-model="id_item_selecionado" :options="opcoes"></b-form-select>
            </div>
        </div>


        <!-- {{returnPdfParagraph(dados_curso_selecionado)[0])}} -->

        
        <!-- <div class="row mt-2" v-html="dados_curso_selecionado" style="text-align: justify">
        </div> -->
         
            <h6>Original:</h6>
            <div class="row mt-2" style="text-align: justify">
                {{dados_curso_selecionado}}
            </div>
            <hr>

            <h6>Alterado pela fórmula:</h6>
            <div class="row mt-2" style="text-align: justify">
                {{returnPdfParagraph(dados_curso_selecionado)}}
            </div>
            
            
             <div class="row mb-5 mt-5">
            <h6>1.3 Justificativa para oferta do curso.</h6>
                <itemInput :showMode="true" titulo="Novo Corrigido:" v-model="formulario_especifico.item_1_3" @valorEmitido="log"></itemInput>
            </div>




            
                <!-- <ul>
                    <li v-for="item in returnPdfParagraph(dados_curso_selecionado)" :key="item.id">
                        {{item}}
                    </li>
                </ul> -->
            

        
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    data() {
        return {
            formularios: [],
            campus_uninove: [],
            curso_selecionado: '******** TESTE - Técnico em Automação Industrial',
            id_item_selecionado: '',
            text_latex: '',
            opcoes: ['item_1_3', 'item_1_4', 'item_1_5_1', 'item_1_5_2', 'item_3', 'item_3_1', 'item_4_1', 'item_4_10', 'item_4_2_1', 'item_4_3']
        }
    },
    computed: {
        cursos_disponiveis(){
            if(this.formularios){
                return this.formularios.map(x => x.nome_curso)
            } else {
                return []
            }
        },
        itens_curso_selecionado(){
            // if(this.curso_selecionado){
            //     return Object.keys(this.formularios[0].formulario_especifico)
            // } else {
            //     return []
            // }
            return ['item_1_3', 'item_1_4', 'item_1_5_1', 'item_1_5_2', 'item_3', 'item_3_1', 'item_4_1', 'item_4_10', 'item_4_2_1', 'item_4_3']
        },
        dados_curso_selecionado(){
            try{
                if(this.curso_selecionado){
                    return this.formularios.filter( x => x.nome_curso == this.curso_selecionado)[0].formulario_especifico[this.id_item_selecionado]
                } else {
                    return []
                }
            } catch(e){
                console.log(e)
                return []
            }
            
        },
        
    },
    methods:{
        





        newHeader(titulo){
            this.text_latex.push({text: titulo, style: 'header', bold: true, tocItem: true})
            this.text_latex.push({text: titulo, style: 'header', pageBreak: 'before', bold: true, tocItem: true})
        },

        newSubHeader(subtitulo){
            this.text_latex.push({text: subtitulo, style: 'header', bold: true, tocItem: true})
        },

        newText(text){
            this.text_latex.push([{text: text, bold: false, alignment: 'justify'}, '\n\n'])
            // this.text_latex.push([{text: text.replace(/(\r\n|\n|\r)/gm, " "), bold: false, alignment: 'justify'}, '\n\n'])
        },

        // newImg(){
        //     this.text_latex.push({image: '/vLhFeevTgQNkxiVdFvBM.png', width: 450})
        // },
        createTableItem_4_2_1(item_4_2_1){
            let tables = ''

            item_4_2_1.forEach(x => tables += this.createLatexTable(x))

            this.text_latex = tables
        },

        createLatexTable(unidade){
            console.log(unidade)
            let latex = ''

            // latex +="\\documentclass[12pt,letterpaper]{article}"
            // latex +="\\usepackage[T1]{fontenc}"
            // latex +="\\usepackage{amsmath}"
            // latex += "\\usepackage[table]{xcolor}"

            
            // latex +="\\begin{document}"
            latex +=    "\\begin{tabular}{ |p{12cm}|  }"
            latex +=        " \\hline"
            latex +=        "\\rowcolor{lightgray} UNIDADE CURRICULAR: " + unidade.nome_unidade + " \\\\"
            latex +=        " \\hline"
            latex +=        "\\rowcolor{lightgray} " + unidade.num_semestre +" \\\\"
            latex +=        " \\hline"
            latex +=        "\\rowcolor{lightgray} CARGA HORÁRIA: " + unidade.carga_horaria +" horas \\\\"
            latex +=        " \\hline"
            latex +=        "\\rowcolor{lightgray} EMENTA: \\\\"
            latex +=        " \\hline"
            latex +=        unidade.ementa + "\\\\"
            latex +=        " \\hline"
            latex +=        "\\rowcolor{lightgray} COMPETÊNCIAS: \\\\"
            latex +=        " \\hline"
            latex +=        "\\begin{enumerate}"
            latex +=            "\\item Adquirir compreensão sobre os elementos e os princípios básicos dos circuitos elétricos CC."
            latex +=                "\\begin{itemize}"
            latex +=                "\\item Conhecimento: conhecer tudo"
            latex +=                "\\item Habilidade: ser hábil"
            latex +=                "\\item Atitude: ter atitude"
            latex +=                "\\end{itemize}"
            latex +=            "\\item Iniciar a utilização de equipamentos de medição e ferramentas relacionados à análise de circuitos elétricos."
            latex +=                "\\begin{itemize}"
            latex +=                "\\item Conhecimento: conhecer tudo"
            latex +=                "\\item Habilidade: ser hábil"
            latex +=                "\\item Atitude: ter atitude"
            latex +=                "\\end{itemize}"
            latex +=           " \\end{enumerate}\\\\"
            latex +=        " \\hline"
            latex +=        "\\rowcolor{lightgray} AVALIAÇÃO: \\\\"
            latex +=        " \\hline"
            latex +=        "Participação e frequência em sala de aula; Apresentação de trabalhos individuais e coletivos; Desempenho nas avaliações escritas e práticas \\\\"
            latex +=        " \\hline"
            latex +=        "\\rowcolor{lightgray} PRÁTICAS: \\\\"
            latex +=        " \\hline"
            latex +=        "Aulas teóricas com apoio de técnicas audiovisuais; Aulas práticas em laboratório; Pesquisas bibliográficas; Visitas técnicas \\\\"
            latex +=        " \\hline"
            latex +=        "\\rowcolor{lightgray} BIBLIOGRAFIA BÁSICA: \\\\"
            latex +=        " \\hline"
            latex +=        "Aulas teóricas com apoio de técnicas audiovisuais; Aulas práticas em laboratório; Pesquisas bibliográficas; Visitas técnicas \\\\"
            latex +=        " \\hline"
            latex +=        "\\rowcolor{lightgray} BIBLIOGRAFIA COMPLEMENTAR: \\\\"
            latex +=        " \\hline"
            latex +=        "Aulas teóricas com apoio de técnicas audiovisuais; Aulas práticas em laboratório; Pesquisas bibliográficas; Visitas técnicas \\\\"
            latex +=        " \\hline"
            latex +=    "\\end{tabular}"
            latex += "\\newpage"
            // latex += "\\end{document}"
            console.log(latex)
            return latex

            // let tables = []
            // // body.push
            // console.log(item_4_2_1)
            // item_4_2_1.forEach((x) => {
            //     let tabela = { style: 'tableExample', table: { body: []}, margin: [0, 5, 0, 15]}
            //     let body = tabela.table.body
            //     // body.push([{text: 'UNIDADE CURRICULAR: Eletricidade CC', bold: true, fillColor: '#dddddd', alignment: 'center'}])
            //     // body.push([x.semestre + '° SEMESTRE'])
            //     // body.push(['CARGA HORÁRIA: ' + x.carga_horaria])
            //     // body.push(['MODALIDADE: ' + x.modalidade])
            //     // body.push([{text: 'EMENTA', bold: true, fillColor: '#dddddd'}]) 
            //     body.push([x.ementa])   

            //     // let competencias = {stack: []}
            //     // x.competencias.forEach(y => {
            //     //     competencias.stack.push({text: y.nome, bold: true})
            //     //     competencias.stack.push({ul: [
            //     //         'Conhecimento: conhecer tudo' + y.conhecimento, 
            //     //         'Habilidade: ser hábil' + y.habilidade, 
            //     //         'Atitude: ter atitude' + y.atitude
            //     //     ]})
            //     // })
            //     // body.push([competencias])

            //     body.push([{text: 'AVALIAÇÃO', bold: true, fillColor: '#dddddd'}]) 
            //     body.push([x.avaliacao])
            //     body.push([{text: 'PRÁTICAS', bold: true, fillColor: '#dddddd'}]) 
            //     body.push([x.praticas])
            //     tables.push(tabela)
            // })
            

            // this.text_latex.push(tables)
        },

        criarPdf(){
            // this.createToc()
            this.createTableItem_4_2_1(this.formulario_escolhido.item_4_2_1)
            
            // _________________ 1. Apresentação e justificativa. _________________
            this.newHeader('1. Apresentação e justificativa')

            // 1.1 Apresentação da Instituição.
            this.newSubHeader('1.1 Apresentação da Instituição')
            this.newText(this.formulario_escolhido.item_4_10)
            // 1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço)
            this.newSubHeader('1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço)')
            this.newText(this.formulario_escolhido.item_4_10)
            // 1.3 Justificativa para oferta do curso
            this.newSubHeader('1.3 Justificativa para oferta do curso')
            this.newText(this.formulario_escolhido.item_1_3)
            // 1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade)
            this.newSubHeader('1.4. Relevância da oferta')
            this.newText(this.formulario_escolhido.item_1_4)
            // 1.5. Objetivos do Curso:
            this.newSubHeader('1.5. Objetivos do Curso')
            this.newText(this.formulario_escolhido.item_1_5)
            // 1.5.1. Objetivo Geral
            this.newSubHeader('1.5.1. Objetivo Geral')
            this.newText(this.formulario_escolhido.item_1_5_1)
            // 1.5.2. Objetivos Específicos.
            this.newSubHeader('1.5.2. Objetivos Específicos')
            this.newText(this.formulario_escolhido.item_1_5_2)


            // _________________ 2. Formas de acesso _________________
            this.newHeader('2. Formas de acesso')
            // 2.1. Pré-requisitos para acesso ao curso
            // 2.2 Formas de acesso ao curso


            // _________________ 3. Perfil profissional de conclusão _________________
            this.newHeader('3. Perfil profissional de conclusão')
            // 3.1 Habilidades e competências desenvolvidas na formação


            // _________________ 4. Organização Curricular _________________
            this.newHeader('4. Organização Curricular')
            // 4.1. Representação gráfica da organização curricular e do itinerário formativo
            this.newSubHeader('4.1. Representação gráfica da organização curricular e do itinerário formativo')
            // this.newImg()

            // 4.2. Apresentação das Unidades Curriculares
            this.newSubHeader('4.2. Apresentação das Unidades Curriculares')
            // 4.2.1. Ementários das Unidades
            this.newSubHeader('4.2.1. Ementários das Unidades')
            

            // 4.3. Plano de realização do Estágio Curricular (somente Enf E radio)
            this.newSubHeader('4.3. Plano de realização do Estágio Curricular')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores
            this.newSubHeader('4.4. Critérios e Mecanismos de Aproveitamento de Conhecimentos e Experiências Anteriores')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem
            this.newSubHeader('4.5. Procedimentos, Mecanismos e Gestão da Avaliação do Processo Ensino-Aprendizagem')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.6 Critérios de avaliação da aprendizagem
            this.newSubHeader('4.6 Critérios de avaliação da aprendizagem')
            // 4.6.1 Estratégias de acompanhamento e superação de dificuldades
            this.newSubHeader('4.6.1 Estratégias de acompanhamento e superação de dificuldades')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.6.2 Estratégias de recuperação
            this.newSubHeader('4.6.2 Estratégias de recuperação')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.6.3 Promoção/Pendência
            this.newSubHeader('4.6.3 Promoção/Pendência')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.7. Regras de Trancamento
            this.newSubHeader('4.7. Regras de Trancamento')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.8 Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado
            this.newSubHeader('4.8 Descrição do funcionamento dos serviços de apoio psicopedagógico e atendimento educacional especializado')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.9 Estágio supervisionado
            this.newSubHeader('4.9 Estágio supervisionado')
            // 4.9.1 Objetivos
            this.newSubHeader('4.9.1 Objetivos')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.9.2 Ambientes de estágio
            this.newSubHeader('4.9.2 Ambientes de estágio')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.9.3 Distribuição da carga horária em cada ambiente
            this.newSubHeader('4.9.3 Distribuição da carga horária em cada ambiente')
            this.newText(this.formulario_escolhido.item_4_10)
            // 4.10 Atividades práticas previstas
            this.newSubHeader('4.10 Atividades práticas previstas')
            this.newText(this.formulario_escolhido.item_4_10)



            // _________________ 5. Instalações e equipamentos _________________
            this.newHeader('5. Instalações e equipamentos')
            // 5.1 Salas de aula
            this.newSubHeader('5.1 Salas de aula')
            // 5.2 Relação de laboratórios
            this.newSubHeader('5.2 Relação de laboratórios')
            // 5.3 Materiais, equipamentos, ferramentas e outros insumos para atividades práticas
            this.newSubHeader('5.3 Materiais, equipamentos, ferramentas e outros insumos para atividades práticas')
            // 5.4 Outras instalações
            this.newSubHeader('5.4 Outras instalações')
            // 5.5 Recursos de acessibilidade
            this.newSubHeader('5.5 Recursos de acessibilidade')
            // 5.6 Biblioteca
            this.newSubHeader('5.6 Biblioteca')
            // 5.7 Acervo bibliográfico do curso
            this.newSubHeader('5.7 Acervo bibliográfico do curso')



            // _________________ 6. Corpo Docente e Tutorial, Coordenador Pedagógico e Pessoal Técnico-Administrativo _________________
            this.newHeader('6. Corpo Docente e Tutorial, Coordenador Pedagógico e Pessoal Técnico-Administrativo')
            // 6.1. Corpo Docente
            this.newSubHeader('6.1. Corpo Docente')
            // 6.2. Corpo Tutorial
            this.newSubHeader('6.2. Corpo Tutorial')
            // 6.3. Coordenador Pedagógico do Curso
            this.newSubHeader('6.3. Coordenador Pedagógico do Curso')
            // 6.4. Pessoal Técnico Administrativo
            this.newSubHeader('6.4. Pessoal Técnico Administrativo')



            // _________________ 7. Diplomas e Certificados Expedidos _________________
            this.newHeader('7. Diplomas e Certificados Expedidos')
            // 7.1 Descrição das informações do diploma a ser emitido
            this.newSubHeader('7.1 Descrição das informações do diploma a ser emitido')
            this.newText(this.formulario_escolhido.item_4_10)
            // 7.2 Descrição das informações dos certificados de qualificação profissional
            this.newSubHeader('7.2 Descrição das informações dos certificados de qualificação profissional')
            this.newText(this.formulario_escolhido.item_4_10)



            // _________________ 8. Descrição do Ambiente Virtual de Aprendizagem _________________
            this.newHeader('8. Descrição do Ambiente Virtual de Aprendizagem')
            this.newHeader('7.2 Descrição das informações dos certificados de qualificação profissional')
            this.newText(this.formulario_escolhido.item_4_10)




            // _________________ 9. Anexos _________________
            this.newHeader('9. Anexos')
            // 9.1 Enxerto do Regimento Interno
            // 9.2 Relação dos Polos de apoio presencial
            // 9.3 Termo de Responsabilidade e Veracidade



            // _________________ 10. Link das Informações e Imagens _________________
            this.newHeader('10. Link das Informações e Imagens')




        },

        solicitaCurso(id_curso){
            db.collection("/nadir").doc(id_curso)
            .get()
            .then((doc) => {
                // console.log(doc.data())
                let dados_curso = doc.data()
                dados_curso['id'] = doc.ref.id
                this.formularios.push(dados_curso)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        },

        detectTagname(elem_text){
            try{
                let htmlObject = document.createElement('div');
                htmlObject.innerHTML = elem_text;
                if(htmlObject.childNodes[0].nodeName == '#text'){
                    // Para texto simples
                    return elem_text
                } else {
                    return htmlObject.children[0].tagName
                }
            } catch(e){
                console.log('Erro ao pegar a tag',e)
            }
            
        },

        returnPdfParagraph(stringElm){
            const doc = new DOMParser().parseFromString(stringElm, 'text/html');
            const arr = [...doc.body.childNodes]
            .map(child => child.outerHTML || child.textContent);
            // console.log(arr);

            let array = arr.map(x => {
                // console.log(this.detectTagname(x))

                if(this.detectTagname(x) == 'UL'){
                    return this.converterTextToListLatex(x)
                } else if(this.detectTagname(x) == 'P'){
                    // console.log(x)
                    return x.match(/<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/)[1]
                } else {
                    return x
                }
            })

            return array
            // let htmlObject = document.createElement('div');
            // htmlObject.innerHTML = stringElm;
            // htmlObject.children[0].tagName

            // if(htmlObject.children[0].children.length != 0){
            //     if(htmlObject.children[0].children[0].tagName === 'STRONG'){
            //         return [{text: htmlObject.textContent, bold: true, alignment: 'justify'}, {text: '_______Subtitulo', style: 'header', bold: true, alignment: 'justify', tocItem: true}]
            //     } else {
            //         return {text: htmlObject.textContent, bold: true, alignment: 'justify'}
            //     }
            // } else {
            //     return [{text: htmlObject.textContent, bold: false, alignment: 'justify'}, {text: htmlObject.textContent.slice(0,20), style: 'subheader', bold: true, alignment: 'justify', tocItem: true}]
            // }
        },

        converterTextToListLatex(list_itens){
            let htmlObject = document.createElement('div');
            htmlObject.innerHTML = list_itens;
            let elem_list_itens = htmlObject.childNodes[0].childNodes
            let latext_list = ' \\begin{itemize}'
            for (let i=0 ; i<elem_list_itens.length ; i++){
                console.log(elem_list_itens[i].textContent)
                let item = ' \\item ' + elem_list_itens[i].textContent// eslint-disable-line
                latext_list += item
            }
            latext_list += " \\end{itemize}"// eslint-disable-line
            console.log(latext_list)
            this.text_latex = latext_list
            return latext_list
        },

        converToLatex(string_elem){
            if(this.detectTagname(string_elem) == 'UL'){
                // this.converterTextToListLatex(string_elem)
                console.log('É uma lista', string_elem)
            } else {
                return 'ERROR:' + string_elem
            }
        },

        createLatexSection(title, paragraph){
            let result = '\\section{' +title +'}' + paragraph
            return result
        },

        

    },
    mounted(){
            // Solicitando os cursos
            db.collection("/nadir")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let dados_formulario = doc.data()
                    dados_formulario['id'] = doc.ref.id
                    this.formularios.push(dados_formulario)
                });
                console.log(this.formularios)
                this.createTableItem_4_2_1(this.formularios[40].formulario_especifico.item_4_2_1)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

            // Solicitando os campus
            db.collection("/campus_uninove")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let campus = doc.data()
                    this.campus_uninove.push(campus)
                });            
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

    }
    
}
</script>